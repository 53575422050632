<template>
  <div>
    <a :class="`save-button ${savedIndex >= 0 ? 'active' : ''}`" href="javascript:void(0)"   @click="saveData(zar_id)">
      <Tooltip placement="left" content="Хадгалсан"  v-if="savedIndex >= 0">
        <i class="ivu-icon ivu-icon-md-bookmark" ></i>
      </Tooltip>
      <Tooltip placement="left" content="Хадгалах" v-else>
        <i class="ivu-icon ivu-icon-md-bookmark" ></i>
      </Tooltip>
    </a>
  </div>
</template>

<script>
import {
  POST_DELETE_BAIGUULAGA_AJIL_BAIR_ZAR,
  POST_SAVE_BAIGUULAGA_AJIL_BAIR_ZAR,
  POST_SAVE_BRIGAD_AJIL_BAIR_ZAR,
} from "../../graphql/queries";
import {mapGetters} from "vuex";
export default {
  name: "save_button_ajil_bair_zar",
  props: ["zar_id", "type"],

  computed: {
    ...mapGetters([
        'user',
        'zarItems',
    ]),
    savedIndex(){
      if(this.zarItems){
        let savedIndex = this.zarItems.findIndex(item=>item.zar_id == this.zar_id);
        return savedIndex;
      } else {
        return -1;
      }
    },
  },
  methods: {
    deleteBaiguullagaData(savedItem){
      this.$apollo.mutate({mutation: POST_DELETE_BAIGUULAGA_AJIL_BAIR_ZAR,
        variables: {
          id: savedItem.id,
        }
      }).then(() =>{
        this.$store.commit("removeFromZar",  savedItem.zar_id);
      });
    },
    saveData(zar_id) {
      if(this.savedIndex >= 0){
        this.deleteBaiguullagaData(this.zarItems[this.savedIndex])
      } else {
        this.$apollo.mutate({
          mutation: this.type == "baiguullaga" ? POST_SAVE_BAIGUULAGA_AJIL_BAIR_ZAR : POST_SAVE_BRIGAD_AJIL_BAIR_ZAR,
          variables: {
            user_id: this.user.id, zar_id: zar_id,
          }
        }).then(({data}) => {
          this.$store.commit("addToZar",  data.createSaveZarBaiguullagaAjilBairZar);
          this.$Notice.success({
            title: this.$t("Successfully_saved"),
            desc: this.$t("Your_saved_ads_Saved_Jobs_ads_menu")
          });
        })
      }
    },

  },
}
</script>
